import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { TPerformanceEnumerable } from '../../models/performance.model';
import { useMemo } from 'react';
import { IDataType } from '../../components/report-table/stores/data-stores/data-store.types';
import { IMetric } from '@monorepo/base/src/components/chart/chart';
import { PerformanceEnumarableLabels } from '@monorepo/tools/src/lib/enums/performance-labels';
import { NewAdminxReportTableStore } from '../../components/report-table/stores/table-stores/adx-repr-table.store';
import { ColumnVisibilityOptions } from '../../components/report-table/columns/columns.types';

const sortWithPriority = (arr: string[], priorityHeaders: string[]): string[] => {
	return arr.sort((a, b) => {
		const aPriority = priorityHeaders.indexOf(a);
		const bPriority = priorityHeaders.indexOf(b);

		// If both headers are in the priority list, sort according to their position in the priority list
		if (aPriority !== -1 && bPriority !== -1) {
			return aPriority - bPriority;
		}

		// If one header is in the priority list, it should come first
		if (aPriority !== -1) {
			return -1;
		}
		if (bPriority !== -1) {
			return 1;
		}

		// If neither header is in the priority list, sort alphabetically
		return a.localeCompare(b);
	});
};

export const useTableStoreMetrics = (adxReprTableStore: NewAdminxReportTableStore) => {
	const dataStore = adxReprTableStore.dataStore;
	const columns = adxReprTableStore.columns.filter(
		col =>
			col.type === 'metrics' &&
			(col.visibility === ColumnVisibilityOptions.AlwaysVisible || col.visibility === ColumnVisibilityOptions.Visible)
	);
	const { startDate, endDate } = useDates();
	const performanceItems = dataStore.getReportGraphData();
	const performanceSummary = dataStore.getReportFooter();
	let currentDate = dayjs(startDate);

	const generateMetrics = (items: IDataType[], summary: IDataType) => {
		const multipleMetrics: { [key: TPerformanceEnumerable]: number[] } = {};
		const _xLabels: string[] = [];

		const availablePerformanceToMetrics: { [key: string]: string } = columns
			.filter(col => col.type === 'metrics' && !col.isAlwaysHidden())
			.reduce((acc, col) => {
				acc[col.header] = col.accessor;
				return acc;
			}, Object());
		const availablePerformanceSlugs = columns.map(col => col.accessor);
		// loop by date and build the multipleMetrics object
		// the multiple metrics object will be object of arrays, each array will be array of numbers that will be displayed in the chart, each array will be one metric of availablePerformanceToMetrics
		while (currentDate.isBefore(dayjs(endDate)) || currentDate.isSame(dayjs(endDate))) {
			_xLabels.push(currentDate.format('MMM D, YYYY'));
			if (items.length > 0) {
				const currentPerformanceDay = items.find(pItem => String(pItem.dt) === currentDate.format('YYYY-MM-DD'));

				if (currentPerformanceDay) {
					// Set all available metrics in multipleMetrics
					Object.entries(currentPerformanceDay).forEach(([performanceName, performanceValue]) => {
						if (availablePerformanceSlugs.includes(performanceName)) {
							if (!multipleMetrics[performanceName]) {
								multipleMetrics[performanceName] = [];
							}
							multipleMetrics[performanceName].push((performanceValue as number) || 0);
						}
					});
				} else {
					const currentPerformanceDay = items[0];
					Object.entries(currentPerformanceDay).forEach(([performanceName]) => {
						if (availablePerformanceSlugs.includes(performanceName)) {
							if (!multipleMetrics[performanceName]) {
								multipleMetrics[performanceName] = [];
							}
							multipleMetrics[performanceName].push(0);
						}
					});
				}
			}

			currentDate = dayjs(currentDate).add(1, 'day');
		}

		const priorityHeaders = [
			PerformanceEnumarableLabels.Cost,
			PerformanceEnumarableLabels.Revenue,
			PerformanceEnumarableLabels.CPM,
			PerformanceEnumarableLabels.ROAS,
		];

		const headers = sortWithPriority(
			columns.map(col => col.header),
			priorityHeaders
		);

		const _metrics: IMetric[] = Object.entries(availablePerformanceToMetrics).map(([performanceLabel, performanceSlug]) => {
			return {
				label: performanceLabel,
				dataset: {
					data: multipleMetrics[performanceSlug],
					label: performanceLabel as string,
				},
				sum: summary[performanceSlug] as number,
				formatter: columns.find(col => col.accessor === performanceSlug)?.graphFormatter,
				selected:
					performanceSlug === availablePerformanceToMetrics[headers[0]] ||
					performanceSlug === availablePerformanceToMetrics[headers[1]],
				visible:
					performanceSlug === availablePerformanceToMetrics[headers[0]] ||
					performanceSlug === availablePerformanceToMetrics[headers[1]] ||
					performanceSlug === availablePerformanceToMetrics[headers[2]] ||
					performanceSlug === availablePerformanceToMetrics[headers[3]],
			};
		});
		// sort the metrics by the priority headers
		_metrics.sort((a, b) => {
			const aPriority = priorityHeaders.indexOf(a.label as PerformanceEnumarableLabels);
			const bPriority = priorityHeaders.indexOf(b.label as PerformanceEnumarableLabels);

			// If both headers are in the priority list, sort according to their position in the priority list
			if (aPriority !== -1 && bPriority !== -1) {
				return aPriority - bPriority;
			}

			// If one header is in the priority list, it should come first
			if (aPriority !== -1) {
				return -1;
			}
			if (bPriority !== -1) {
				return 1;
			}

			// If neither header is in the priority list, sort alphabetically
			return a.label.localeCompare(b.label);
		});

		return {
			metrics: _metrics,
			labels: _xLabels,
			legendOptions: headers,
		};
	};

	const { metrics, labels, legendOptions } = useMemo(
		() => generateMetrics(performanceItems, performanceSummary),
		[performanceItems, performanceSummary]
	);

	return { metrics, labels, legendOptions };
};
