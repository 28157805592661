import { CampaignIdColumnConfig, CampaignNameColumnConfig } from '../../../columns/adx-repr.columns';
import { DimensionsColumnConfig, MetricsColumnConfig } from '../../../columns/columns';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';

export const campaignTrashedBidKeysTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxTrashedBidKeysReport',
		sort: [
			{
				column: 'total_wins',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new DimensionsColumnConfig({
			accessor: 'closed_date',
			name: 'closed_date',
			header: 'Closed Date',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new DimensionsColumnConfig({
			accessor: 'cache_key',
			name: 'cache_key',
			header: 'Cache key',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new CampaignNameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new CampaignIdColumnConfig(),
		new DimensionsColumnConfig({
			accessor: 'stop_reason',
			name: 'stop_reason',
			header: 'Stop reason',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new DimensionsColumnConfig({
			accessor: 'cache_data',
			name: 'cache_data',
			header: 'Cache data',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_wins',
			name: 'total_wins',
			header: 'Total wins',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_optimal_cost',
			name: 'total_optimal_cost',
			header: 'Total optimal cost',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_clicks',
			name: 'total_clicks',
			header: 'Total clicks',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_calculate_gain',
			name: 'total_calculate_gain',
			header: 'Total calculate gain',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_conversions',
			name: 'total_conversions',
			header: 'Total conversions',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_money_gained',
			name: 'total_money_gained',
			header: 'Total money gained',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_bids',
			name: 'total_bids',
			header: 'Total bids',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'calculated_q1_gain',
			name: 'calculated_q1_gain',
			header: 'Calculated Q1 gain',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_money_spent_wins',
			name: 'total_money_spent_wins',
			header: 'Total money spent wins',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'total_money_bid',
			name: 'total_money_bid',
			header: 'Total money bid',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			accessor: 'calculated_optimal_gain',
			name: 'calculated_optimal_gain',
			header: 'Calculated optimal gain',
			type: 'dimensions',
			visibility: ColumnVisibilityOptions.Visible,
		}),
	],
	tableName: 'adxReprCampaignTrahedBidKeys',
	includeChart: false,
};
