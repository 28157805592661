import styles from './table-info.module.scss';

interface IPageSize {
	total: string | number;
	label: string;
}

export const TableInfo = (props: IPageSize) => {
	const { total, label = 'rows' } = props;

	return (
		<div className={styles.wrapper}>
			{total.toLocaleString()} {label}
		</div>
	);
};
