import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	ScriptableTooltipContext,
	TooltipModel,
	TooltipItem,
	ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { numberFormatter } from '@monorepo/tools/src/lib/utils/number';
import styles from './chart.module.scss';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ChartTooltip, ITooltipData } from './chart-tooltip/chart-tooltip';
import { Legend } from './chart-legend/chart-legend';
import { PerformanceEnumarableLabels } from '@monorepo/tools/src/lib/enums/performance-labels';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';

export interface IMetric {
	label: string;
	dataset: {
		label: string;
		data: number[];
	};
	formatter?: (val?: number) => string | null;
	sum?: number;
	selected?: boolean; // selected in legend
	visible?: boolean; // visible in legend
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);

// TODO - @MFP move to core
// TODO - change to enums
export const ChartColors = [
	'#7F56D9', // Purple
	'#DD2590', // Pink
	'#3e7eff', // Blue
	'#69d4a2', // Green
	'#0BA5EC', // Light Blue
	'#EC4A0A', // Orange
	'#B692F6',
	'#53389E',
	'#6172F3',
	'#1D2939',
	'#7E2410',
];

export interface IChart {
	metrics: IMetric[];
	labels: string[];
	isError?: boolean;
	isLoading?: boolean;
	legendOptions?: string[];
	debugProps?: IDebugProps;
}

const _buckets = {
	dollars: [PerformanceEnumarableLabels.Revenue, PerformanceEnumarableLabels.Cost],
	percentages: [PerformanceEnumarableLabels.ROAS, PerformanceEnumarableLabels.CVR],
};

export const Chart = (props: IChart) => {
	const { metrics, labels = [], isLoading, legendOptions } = props;
	const isOneLabelDisplay = labels?.length === 1;
	const [metricsAmount, setMetricsAmount] = useState<number | null>();
	const [visibleMetrics, setVisibleMetrics] = useState<IMetric[]>([]); // visible metrics

	const minDataPerVisibleMetric = visibleMetrics
		.map(metric => {
			if (!metric.selected) {
				return 0;
			}
			const val = metric?.dataset?.data || [];
			return Math.min(...val);
		})
		.filter(val => val !== 0);

	const [tooltipData, setTooltipData] = useState<ITooltipData>({
		opacity: 0,
		top: 0,
		left: 0,
		dataPoints: [],
	});
	const isLoadingChart = isLoading || visibleMetrics.length === 0;
	const selectedMetrics = visibleMetrics.filter(metric => metric.selected);
	const chartRef = useRef<ChartJS<'line', number[], string>>(null);

	useEffect(() => {
		const _visibleMetrics = metrics.filter(metricValue => metricValue.visible);
		setVisibleMetrics(_visibleMetrics);
		setMetricsAmount(_visibleMetrics.length);
	}, [metrics]);

	const replaceMetric = (index: number, afterMetric: PerformanceEnumarableLabels) => {
		const newMetrics = [...visibleMetrics];

		const metricToDisplay = metrics.find(m => m.label === afterMetric);
		if (!metricToDisplay) {
			return null;
		}
		const cloneMetric = { ...metricToDisplay };

		cloneMetric.selected = true;
		cloneMetric.visible = true;
		// TODO
		newMetrics[index] = cloneMetric;
		setVisibleMetrics(newMetrics);
	};

	const toggleMetric = (index: number) => {
		const newMetrics = [...visibleMetrics];

		const metric = visibleMetrics[index];
		if (!metric) {
			return null;
		}

		metric.selected = Boolean(!metric?.selected);
		metric.visible = true;
		setVisibleMetrics(newMetrics);
	};

	const options: ChartOptions<'line'> = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			// for the tooltip to be visible on hover
			intersect: false,
			axis: 'x' as const,
		},
		datasets: {
			line: {
				// no points on line for default
				pointRadius: isOneLabelDisplay ? 3 : 0,
			},
		},
		layout: {
			padding: {
				top: 10,
				right: 20,
				bottom: 10,
				left: 10,
			},
		},
		clip: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
				external: (context: ScriptableTooltipContext<'line'>) => {
					const tooltipModel = context.tooltip as TooltipModel<'line'>;

					if (tooltipModel.opacity === 0) {
						if (tooltipData.opacity !== 0) {
							setTooltipData(prev => ({ ...prev, opacity: 0 }));
						}
						return;
					}

					const { dataPoints, caretX } = tooltipModel;

					const newDataPoints = dataPoints
						.filter((point: TooltipItem<'line'>) => point.dataset.showLine)
						.map((point: TooltipItem<'line'>) => {
							const { label, formattedValue, dataset } = point;
							const { backgroundColor } = dataset;

							return {
								value: formattedValue,
								date: label,
								color: backgroundColor ? backgroundColor?.toString() : '',
								label: dataset.label || '',
							};
						});

					const newTooltipData = {
						opacity: 1,
						left: caretX,
						dataPoints: newDataPoints,
					};

					if (JSON.stringify(tooltipData) !== JSON.stringify(newTooltipData)) {
						// Only rerender when we have new data to show
						setTooltipData(newTooltipData);
					}
				},
			},
		},
		elements: {
			// no points on line for default
			point: {
				radius: isOneLabelDisplay ? 3 : 0,
			},
		},
		animation: false,
		scales: {
			x: {
				display: false,
				offset: isOneLabelDisplay,
			},
			firstY: {
				min: minDataPerVisibleMetric[0],
				type: 'linear',
				display: true,
				position: 'left',
				afterFit(scale) {
					scale.width = 55;
				},
				ticks: {
					font: {
						size: 11,
					},
					color: () => {
						if (isLoadingChart) {
							return 'transparent';
						}
						if (selectedMetrics.length === 1 || selectedMetrics.length === 2) {
							// show y ticks in case of 1 or 2 metrics selected
							return 'black';
						}
						return 'transparent';
					},
					count: 3,
				},
				grid: {
					display: true,
					drawBorder: false,
					drawOnChartArea: false, // only want the grid lines for one axis to show up
				},
			},
			secondY: {
				min: Math.min(minDataPerVisibleMetric?.[1] || 0, 0),
				type: 'linear' as const,
				display: true,
				position: 'right' as const,
				afterFit(scale) {
					scale.width = 40;
				},
				ticks: {
					font: {
						size: 11,
					},
					color: () => {
						if (isLoadingChart) {
							return 'transparent';
						}
						if (selectedMetrics.length === 2 && !isSameBucket(selectedMetrics)) {
							// show y ticks in case of 2 metrics selected
							return 'black';
						}
						return 'transparent';
					},
					count: 3,
				},
				grid: {
					display: true,
					drawBorder: false,
				},
			},
			thirdY: {
				min: 0,
				display: false,
				position: 'right' as const,
			},
			forthY: {
				min: 0,
				display: false,
				position: 'right' as const,
			},
		},
	};

	const isSameBucket = (metrics: IMetric[]): boolean => {
		const bucket = _buckets.dollars.includes(metrics[0].label as PerformanceEnumarableLabels)
			? _buckets.dollars
			: _buckets.percentages.includes(metrics[0].label as PerformanceEnumarableLabels)
			? _buckets.percentages
			: null;
		if (!bucket) {
			return false;
		}

		return metrics.every(i => bucket.includes(i.label as PerformanceEnumarableLabels));
	};

	const getMetricFormatter = (metric: IMetric) => {
		const metricFormatter = metric.formatter;

		if (!metricFormatter) {
			// default metric formatter
			return (val: string | number) => {
				return numberFormatter.format(typeof val === 'string' ? parseInt(val) : val);
			};
		}
		// specific to dataset metric formatter
		return (val: string | number) => {
			const valueFormatted = metricFormatter(+val);
			return valueFormatted || '';
		};
	};

	// hate this flag, need it in case of two selected metrics.
	// in case of two selected metrics we will display the ticks only in firstY scale and secondY scale,
	// we need to check which current selected metric already took the firstY and then we wiil take the secondY (line 268)
	let isFirstY = false;
	const getYAxisID = (metricValue: IMetric, metricIndex: number) => {
		if (selectedMetrics.length === 1) {
			if (!metricValue.selected) {
				return 'forthY';
			}
			if (options.scales && options.scales.firstY && options.scales.firstY.ticks) {
				// f ts
				options.scales.firstY.ticks.callback = getMetricFormatter(metricValue);
			}
			return 'firstY';
		}

		if (selectedMetrics.length === 2) {
			if (!metricValue.selected) {
				return 'forthY';
			}
			if (!isFirstY) {
				if (options.scales && options.scales.firstY && options.scales.firstY.ticks) {
					// f ts
					options.scales.firstY.ticks.callback = getMetricFormatter(metricValue);
				}
				isFirstY = true;
				return 'firstY';
			} else if (isSameBucket(selectedMetrics) && options?.scales?.firstY?.ticks) {
				options.scales.firstY.ticks.callback = getMetricFormatter(metricValue);
				return 'firstY';
			}
			if (options.scales && options.scales.secondY && options.scales.secondY.ticks) {
				// f ts
				options.scales.secondY.ticks.callback = getMetricFormatter(metricValue);
			}
			return 'secondY';
		}

		// if there are 0 or 3 or 4 or more selected metrics we wont show the ticks in the y axes
		// TODO - when we will want more scales will need to change it
		const scales = ['firstY', 'secondY', 'thirdY', 'forthY'];
		return scales[metricIndex];
	};

	const datasets = [];
	if (visibleMetrics.length > 0 && metricsAmount) {
		for (let index = 0; index < metricsAmount; index++) {
			const metricValue = visibleMetrics[index];

			// let scale = null;
			// if (index > 2) {
			// TODO - generate scale in case of more then 4 metrics
			// 	scale = generateScale(index);
			// }

			datasets.push({
				yAxisID: getYAxisID(metricValue, index),
				borderWidth: 2,
				tension: 0.1,
				borderColor: ChartColors[index],
				backgroundColor: ChartColors[index],
				showLine: Boolean(metricValue.selected),
				pointBorderColor: metricValue.selected ? ChartColors[index] : 'transparent',
				pointBackgroundColor: metricValue.selected ? ChartColors[index] : 'transparent',
				pointHoverBorderWidth: 0,
				...metricValue.dataset,
			});
		}
	}

	const lineData = {
		labels,
		datasets,
	};

	return (
		<Fragment>
			<Legend
				legendOptions={legendOptions}
				isLoading={isLoadingChart}
				metrics={visibleMetrics}
				toggleMetric={toggleMetric}
				replaceMetric={replaceMetric}
			/>

			<div className={`${styles.lineWrapper}`}>
				<ChartTooltip data={tooltipData} chartWidth={chartRef?.current?.width} />
				<Line options={options} data={lineData} ref={chartRef} />
			</div>

			<div className={`${styles.dates} ${isOneLabelDisplay ? styles.center : ''}`}>
				{isOneLabelDisplay ? (
					<span>{labels[0]}</span>
				) : (
					<Fragment>
						<span>{labels[0]}</span>
						<span>{labels[labels.length - 1]}</span>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};
