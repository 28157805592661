import {
	CampaignNameColumnConfig,
	DateColumnConfig,
	StrategyIdColumnConfig,
	ParentIdColumnConfig,
	CampaignTypeColumnConfig,
	PriorityColumnConfig,
	SecondaryPriorityColumnConfig,
	CacheKeyGeneratorColumnConfig,
	BidKeyGeneratorColumnConfig,
	DestUrlColumnConfig,
	CreatedAtColumnConfig,
	UpdatedAtColumnConfig,
	SecondBidFactorColumnConfig,
	UseOptimalGainForMaxLossCalculationColumnConfig,
	CreativeSelectionModeColumnConfig,
	MaxCpmColumnConfig,
	MaxLossPerKeyColumnConfig,
	MaxWinsPerConversionColumnConfig,
	MaxWinsPerClickColumnConfig,
	MaxClicksPerConversionColumnConfig,
	DailySalesColumnConfig,
	UseDomainBudgetLimitColumnConfig,
	AllowMultipleSalesColumnConfig,
	DefaultPayoutColumnConfig,
	DailyDomainMaxLossColumnConfig,
	DailyMaxSalesColumnConfig,
} from '../../../columns/adx-repr.columns';
import { generateAdxReprPerformanceColumns } from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const campaignDailyReportPerformanceTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'dt',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new CampaignNameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new DateColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new StrategyIdColumnConfig(),
		new ParentIdColumnConfig(),
		new CampaignTypeColumnConfig(),
		new PriorityColumnConfig(),
		new SecondaryPriorityColumnConfig(),
		new CacheKeyGeneratorColumnConfig(),
		new BidKeyGeneratorColumnConfig(),
		new DestUrlColumnConfig(),
		new CreatedAtColumnConfig(),
		new UpdatedAtColumnConfig(),
		new SecondBidFactorColumnConfig(),
		new UseOptimalGainForMaxLossCalculationColumnConfig(),
		new CreativeSelectionModeColumnConfig(),
		new MaxCpmColumnConfig(),
		new MaxLossPerKeyColumnConfig(),
		new MaxWinsPerConversionColumnConfig(),
		new MaxWinsPerClickColumnConfig(),
		new MaxClicksPerConversionColumnConfig(),
		new DailySalesColumnConfig(),
		new UseDomainBudgetLimitColumnConfig(),
		new AllowMultipleSalesColumnConfig(),
		new DefaultPayoutColumnConfig(),
		new DailyDomainMaxLossColumnConfig(),
		new DailyMaxSalesColumnConfig(),
		...generateAdxReprPerformanceColumns(),
	],
	includeChart: true,
	tableName: 'adxReprCampaignDailyReportPerformance',
};
