import { ReportFilterType } from '../stores/data-stores/old-report.types';
import { IDataStore } from '../stores/data-stores/data-store.types';

export enum FilterType {
	Text = 'text',
	Number = 'number',
	Select = 'select',
	SelectFromDataSource = 'selectFromDataSource',
}

export interface IFilterOption {
	value: string;
	label: string;
}

export interface FilterAction {
	label: string;
	value: ReportFilterType;
}

export interface IFilterDefinition {
	type: FilterType;
	actions: FilterAction[];
	options?: IFilterOption[];
	multi?: boolean;
	selectFromDataSource?: {
		store: IDataStore<unknown>;
		cb: (value: unknown) => IFilterOption;
	};
}
