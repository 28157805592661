import { ReportFilterType } from '../stores/data-stores/old-report.types';
import { NumbersFilterDefinition, TextFilterDefinition } from '../table-filters/filters.definitions';
import { FilterType } from '../table-filters/filters.types';
import { DataColumnConfig } from './columns';
import { IColumnConfig } from './columns.types';

export class DomainScoreColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'score',
			accessor: 'score',
			header: 'Score',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class DomainFormatColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'format',
			accessor: 'format',
			header: 'Format',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class DomainScorerClassColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'scorer_class',
			accessor: 'scorerClass',
			header: 'Scorer Class',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class DomainSalesColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'sales',
			accessor: 'sales',
			header: 'Sales',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class DomainPretargetingIdColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'id',
			accessor: 'id',
			header: 'ID',
			...params,
		});
	}
}

export class DomainBillingIdColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'billing_id',
			accessor: 'billingId',
			header: 'Billing ID',
			...params,
		});
	}
}

export class DomainTypeColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'type',
			accessor: 'type',
			header: 'Type',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class DomainPretargetingModeColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'mode',
			accessor: 'mode',
			header: 'Mode',
			availableFilters: {
				type: FilterType.Select,
				actions: [
					{
						label: 'Include',
						value: ReportFilterType.INCLUDE,
					},
				],
				options: [
					{
						label: 'INCLUSIVE',
						value: 'INCLUSIVE',
					},
					{
						label: 'EXCLUSIVE',
						value: 'EXCLUSIVE',
					},
				],
			},
			...params,
		});
	}
}

export class DomainNameColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'name',
			accessor: 'name',
			header: 'Name',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}
