import { observer } from 'mobx-react';
import { FC } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';

import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';

import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const DefaultPayout: FC = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const defaultPayout = campaign?.getDefaultPayout();

	return (
		<Card title={'Default Payout'} icon={'currency-dollar-circle'} id={'defaultPayout'}>
			<Input
				type={'number'}
				icon={'currency-dollar'}
				value={defaultPayout?.toString()}
				placeholder={'Default Payout'}
				onValue={value => campaign?.setDefaultPayout(parseFloat(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'default_payout')] }}
			/>
		</Card>
	);
});
