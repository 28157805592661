import { useRef, useState } from 'react';
import styles from './table-filters.module.scss';
import { IndexLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { PrimaryLink, SecondaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import {
	InvertedReportFilterTypesBackMap,
	InvertedReportFilterTypesMap,
	ReportFilter,
	ReportFilterType,
} from '../stores/data-stores/old-report.types';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { SelectNew } from '@monorepo/base/src/components/select-new/select-new';
import { SelectFromDataSourceFilterOption } from './select-from-ds.filter';
import { useTableContext } from '../table-component';
import { TableSelectFilter } from './select.filter';
import { TableInputFilter } from './input.filter';
import { IFilterDefinition } from './filters.types';
import { IColumnConfig } from '../columns/columns.types';

const theme = getTheme();

interface TableFiltersProps {
	onClose?: () => void;
}

export interface FilterOptionProps {
	column: IColumnConfig;
	onApply: (x: ReportFilter) => void;
	onCanceled: () => void;
	initialState?: ReportFilter;
}

interface FilterApplyAndCancelButtonsProps {
	onApply: () => void;
	onCanceled: () => void;
	disableApply: boolean;
}

export const FilterApplyAndCancelButtons = ({ onApply, onCanceled, disableApply }: FilterApplyAndCancelButtonsProps) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
			<SecondaryLink
				font={IconFonts.Outlined}
				iconColor={getTheme().actionLinerActionIconsColor}
				className={styles.columnPickerResetButton}
				iconSize={'18px'}
				width={'80px'}
				onClick={onCanceled}>
				Cancel
			</SecondaryLink>
			<PrimaryLink disabled={disableApply} width={'80px'} onClick={onApply}>
				Apply
			</PrimaryLink>
		</div>
	);
};

const FilterOption = (props: FilterOptionProps) => {
	const filter: IFilterDefinition | undefined = props.column.availableFilters;
	switch (filter?.type) {
		case 'selectFromDataSource':
			return <SelectFromDataSourceFilterOption {...props} />;
		case 'select':
			return <TableSelectFilter {...props} />;
		default:
			return <TableInputFilter {...props} />;
	}
};

export const ActionToSimbolMap: Record<ReportFilterType, string> = {
	[ReportFilterType.EQUALS]: '=',
	[ReportFilterType.CONTAINS]: 'contains',
	[ReportFilterType.NOT_CONTAINS]: 'not contains',
	[ReportFilterType.NOT_EQUALS]: '!=',
	[ReportFilterType.IS_NOT_NULL]: 'is not null',
	[ReportFilterType.IS_NULL]: 'is null',
	[ReportFilterType.IN]: 'in',
	[ReportFilterType.NOT_IN]: 'not in',
	[ReportFilterType.GREATER]: '>',
	[ReportFilterType.GREATER_AND_EQUALS]: '>=',
	[ReportFilterType.LESS]: '<',
	[ReportFilterType.LESS_AND_EQUALS]: '<=',
	[ReportFilterType.INCLUDE]: 'include',
};

export const AppliedFilter = ({
	filter,
	column,
	onRemove,
	onApply,
}: {
	filter: ReportFilter;
	column: IColumnConfig;
	onRemove: () => void;
	onApply: (x: ReportFilter) => void;
}) => {
	if (!column) {
		console.error(
			`Column not defined, cannot render applied filter: ${JSON.stringify(
				filter
			)}.\n did you initiate the table with filter that exists in the columns?`
		);
		return null;
	}
	const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
	const appliedFiltersRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(appliedFiltersRef, () => {
		setIsFilterMenuOpen(false);
	});

	const onApplyAndClose = (filter: ReportFilter) => {
		onApply(filter);
		setIsFilterMenuOpen(false);
	};

	let filterType = filter.filterType;
	if (InvertedReportFilterTypesBackMap.has(filter.filterType) && filter.inverted) {
		filterType = InvertedReportFilterTypesBackMap.get(filter.filterType) || filter.filterType;
	}

	return (
		<div ref={appliedFiltersRef} className={styles.appliedFilterButton}>
			<div onClick={() => setIsFilterMenuOpen(true)} className={styles.appliedFilterButtonContent}>
				<Icon
					onClick={e => {
						e.stopPropagation();
						onRemove();
					}}
					className={styles.closeButton}
					isMFP={true}
					color={getTheme().primaryColor}>
					x-close
				</Icon>
				{column.header} {ActionToSimbolMap[filterType]} '{filter.value[0]}' {filter.value.length > 1 ? '...' : ''}
			</div>
			<Menu unstyled={true} isOpen={isFilterMenuOpen} alignToLeft={true} className={styles.columnsFiltersList}>
				<div className={styles.closeAndBackButton}>
					<Icon
						onClick={() => setIsFilterMenuOpen(false)}
						className={styles.closeButton}
						isMFP={true}
						color={getTheme().primaryColor}>
						x-close
					</Icon>
				</div>
				<div className={styles.filterOptionWrapper}>
					<FilterOption
						initialState={{ ...filter, filterType }}
						column={column}
						onApply={onApplyAndClose}
						onCanceled={() => setIsFilterMenuOpen(false)}
					/>
				</div>
			</Menu>
		</div>
	);
};

export const TableFilters = ({ onClose }: TableFiltersProps) => {
	const { tableStore } = useTableContext();

	const filterableColumns = tableStore.columns.filter(col => !!col.availableFilters && !col.isAlwaysHidden());
	const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
	const [currentFilterColumn, setCurrentFilterColumn] = useState<IColumnConfig | undefined>(undefined);
	const tableFiltersRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(tableFiltersRef, () => {
		setIsFilterMenuOpen(false);
		setCurrentFilterColumn(undefined);
		if (onClose) {
			onClose();
		}
	});

	const handleApply = (filter: ReportFilter) => {
		const inverted = InvertedReportFilterTypesMap.has(filter.filterType);
		const filterType = InvertedReportFilterTypesMap.get(filter.filterType) || filter.filterType;
		const newFilter: ReportFilter = {
			column: filter.column,
			value: filter.value,
			filterType,
			inverted,
		};
		tableStore.onFilterChange([...tableStore.tableState.filters, newFilter]);
		setIsFilterMenuOpen(false);
		setCurrentFilterColumn(undefined);
	};

	const handleModifiedFilter = (filter: ReportFilter) => {
		const newFilters = tableStore.tableState.filters.map(f => {
			if (f.column === filter.column) {
				const isInverted = InvertedReportFilterTypesMap.has(filter.filterType);
				const filterType = InvertedReportFilterTypesMap.get(filter.filterType) || filter.filterType;
				return {
					column: filter.column,
					filterType,
					value: filter.value,
					inverted: isInverted,
				};
			}
			return f;
		});
		tableStore.onFilterChange(newFilters);
		setIsFilterMenuOpen(false);
	};

	return (
		<div ref={tableFiltersRef} className={styles.tableFiltersWrapper}>
			{tableStore.tableState.filters.map((filter, index) => (
				<AppliedFilter
					filter={filter}
					key={index}
					onRemove={() => {
						const newFilters = tableStore.tableState.filters.filter((_, i) => i !== index);
						tableStore.onFilterChange(newFilters);
					}}
					column={tableStore.columns.find(col => col.name === filter.column) as IColumnConfig}
					onApply={handleModifiedFilter}
				/>
			))}
			<IndexLinerButton>
				<SecondaryText
					icon={'plus'}
					iconColor={theme.actionLinerActionIconsColor}
					iconSize={'20px'}
					onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}>
					Add Filter
				</SecondaryText>
			</IndexLinerButton>
			<Menu unstyled={true} isOpen={isFilterMenuOpen} alignToLeft={true} className={styles.columnsFiltersList} onClose={onClose}>
				<div className={`${styles.closeAndBackButton} ${currentFilterColumn ? styles.fullWidth : ''}`}>
					{currentFilterColumn ? (
						<Icon
							onClick={() => setCurrentFilterColumn(undefined)}
							className={styles.closeButton}
							isMFP={true}
							color={getTheme().primaryColor}>
							chevron-left
						</Icon>
					) : (
						<div />
					)}
					<Icon
						onClick={() => setIsFilterMenuOpen(false)}
						className={styles.closeButton}
						isMFP={true}
						color={getTheme().primaryColor}>
						x-close
					</Icon>
				</div>
				{currentFilterColumn ? (
					<div className={styles.filterOptionWrapper}>
						<FilterOption
							column={currentFilterColumn}
							onApply={handleApply}
							onCanceled={() => setCurrentFilterColumn(undefined)}
						/>
					</div>
				) : (
					<SelectNew
						options={filterableColumns
							.sort((a, b) => {
								if (a.header < b.header) {
									return -1;
								}
								if (a.header > b.header) {
									return 1;
								}
								return 0;
							})
							.map(col => ({ label: col.header, value: col.name }))}
						onSelect={val => setCurrentFilterColumn(filterableColumns.find(col => col.name === val[0]))}
						placeholder={'Search...'}
						required={true}
						isLoading={false}
						classNames={{ list: styles.filterColumnPicker }}
					/>
				)}
			</Menu>
		</div>
	);
};
