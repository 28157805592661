import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { ReactElement, useEffect, useRef } from 'react';
import { Directions } from '../../../../../base/src/components/buttons/buttons';
import { IControlledStore, useStores } from '../../../hooks/use-stores';

export enum TooltipThemes {
	Dark = 'dark',
	Light = 'light',
}

interface ITooltip {
	children: ReactElement;
	content: ReactElement | string;
	theme?: TooltipThemes;
	delay?: number;
	className?: string;
	is?: boolean;
	isFullWidth?: boolean;
	align?: Alignments;
	direction?: Directions;
	top?: string; // TODO - fix a bug where someting the tooltip is not align to center
	bottom?: string; // TODO - fix a bug where someting the tooltip is not align to center
	forceHover?: boolean;
	isHoveredOnTooltip?: boolean;
	isPositionFixed?: boolean;
	maxHeight?: number;
}

// TODO - take it outside
export enum Alignments {
	FromTop = 'alignToTop',
	FromBottom = 'alignToBottom',
	FromLeft = 'alignToLeft',
	FromRight = 'alignToRight',
}

export const Tooltip = (props: ITooltip) => {
	const {
		children,
		content,
		theme = TooltipThemes.Dark,
		delay = 250,
		isPositionFixed = false,
		is = true,
		align = undefined,
		direction = undefined,
		forceHover = false,
		isHoveredOnTooltip = false,
		maxHeight,
	} = props;
	const hoverRef = useRef<HTMLElement>(null);
	const { tooltipsStore } = useStores<IControlledStore>();
	const didMount = useDidMount();

	useEffect(() => {
		if (didMount) {
			tooltipsStore.addTooltip({
				hoverRef,
				content,
				theme,
				delay,
				isPositionFixed,
				is,
				direction,
				align,
				forceHover,
				isHoveredOnTooltip,
				maxHeight,
			});
		}
	}, [didMount]);

	return (
		<span ref={hoverRef} style={{ width: '100%' }}>
			{children}
		</span>
	);
};
